import "bootstrap";

import "../img/favicon.png" 
import "../img/solutionhero_logo_erweitert_green.png" 
import "../img/solutionhero_logo_erweitert_white.png" 

(function ($) {
    "use strict"; // Start of use strict

    // Smooth scrolling using jQuery easing
    // Select all links with hashes
    $("a[href*=\"#\"]")
        // Remove links that don"t actually link to anything
        .not("[href=\"#\"]")
        .not("[href=\"#0\"]")
        .on("click", function (event) {
            // On-page links
            if (
                location.pathname.replace(/^\//, "") == this.pathname.replace(/^\//, "")
                &&
                location.hostname == this.hostname
            ) {
                // Figure out element to scroll to
                var target = $(this.hash);
                target = target.length ? target : $("[name=" + this.hash.slice(1) + "]");
                // Does a scroll target exist?
                if (target.length) {
                    // Only prevent default if animation is actually gonna happen
                    event.preventDefault();
                    $("html, body").animate({
                        scrollTop: target.offset().top
                    }, 1000, function () {
                        // Callback after animation
                        // Must change focus!
                        var $target = $(target);
                        $target.trigger("focus");
                        if ($target.is(":focus")) { // Checking if the target was focused
                            return false;
                        } else {
                            $target.attr("tabindex", "-1"); // Adding tabindex for elements not focusable
                            $target.trigger("focus"); // Set focus again
                        };
                    });
                }
            }
        });

    // Scroll to top button appear
    $(document).on("scroll", function () {
        var scrollDistance = $(this).scrollTop();
        if (scrollDistance > 100) {
            $(".back-to-top").fadeIn();
        } else {
            $(".back-to-top").fadeOut();
        }
    });

})(jQuery); // End of use strict